
import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header';
import Footer from './footer';

import './layout.css'
import '../styles/index.css'
//import 'semantic-ui-css/semantic.min.css'
import '../styles/semantic-override.css';
//import buttonStyles from '../styles/buttons/main.module.scss';

const Layout = ({ children }) => (

  <div>
    <Helmet
      title="Blueprint Education | 蓝图教育 | Test Prep, College & Boarding School Admissions Consulting"
      meta={[
        { name: 'description', content: "Shanghai's premier provider of ACT and SAT courses, boarding school and college application consulting services." },
        { name: 'keywords', content: 'ACT Test Prep, SAT Test Prep, College Admissions Consulting, Boarding School Admissions Consulting' },
      ]}
    />
    <div>
      <Header />
      {children}
      <Footer />
    </div>
  </div>
)


Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
