import React from 'react';

import styles from './contact.module.scss';

import Layout from '../../components/layout';

import { map_puxi, map_pudong } from '../../images/contact';

import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Contact extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      showAlert: false,
      firstName: '',
      lastName: '',
      email: '',
      wechat: '',
      checkedA: false,
      checkedB: false,
      checkedC: false,
      checkedD: false,
      checkedE: false,
      checkedF: false,
      checkedG: false,
      checkedH: false,
      checkedI: false,
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  handleCheck = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleFormSubmit = (e) => {
    this.setState({
     isLoading: true,
    })

    setTimeout(() => {
      this.setState({
        isLoading: false,
        showAlert: true
      })
    }, 2000)
  }

  render() {
    return (
      <Layout>
        <div className={styles.contactPage}>
            {this.state.isLoading ? 
              <div className={styles.loadingOverlay}>
                <CircularProgress color='primary'/>
              </div>
              :
              null
            }
          <div className={styles.section}>   
            <div className={styles.header}>Contact Us</div>
            <div className={styles.description}>Learn how our programs can help your family succeed. Get in touch to learn more about our programs or to schedule a free consultation today.</div>
            <div className={styles.formWrapper}>
              <form className={styles.formWrapperInner} noValidate autoComplete="off">
                <TextField
                  id="first-name"
                  label="First Name"
                  className={styles.textField}
                  value={this.state.firstName}
                  onChange={this.handleChange('firstName')}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="last-name"
                  label="Last Name"
                  className={styles.textField}
                  value={this.state.lastName}
                  onChange={this.handleChange('lastName')}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="email-name"
                  label="Email"
                  className={styles.textField}
                  value={this.state.email}
                  onChange={this.handleChange('email')}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="wechat"
                  label="Phone / Wechat ID"
                  className={styles.textField}
                  value={this.state.wechat}
                  onChange={this.handleChange('wechat')}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="school"
                  label="School Name"
                  className={styles.textField}
                  value={this.state.wechat}
                  onChange={this.handleChange('school')}
                  margin="normal"
                  variant="outlined"
                />
                <TextField
                  id="grade"
                  label="Grade Level"
                  className={styles.textField}
                  value={this.state.wechat}
                  onChange={this.handleChange('grade')}
                  margin="normal"
                  variant="outlined"
                />
                <div className={styles.descriptionWrapper}>
                  <div className={styles.description}>Which of the following are you interested in?</div>
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedA}
                        onChange={this.handleCheck('checkedA')}
                        value="checkedA"
                        color="primary"
                      />
                    }
                    label="High School Counseling"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedB}
                        onChange={this.handleCheck('checkedB')}
                        value="checkedB"
                        color="primary"
                      />
                    }
                    label="College Counseling"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedC}
                        onChange={this.handleCheck('checkedC')}
                        value="checkedC"
                        color="primary"
                      />
                    }
                    label="Academic Mentorship"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedD}
                        onChange={this.handleCheck('checkedD')}
                        value="checkedD"
                        color="primary"
                      />
                    }
                    label="SAT Prep"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedE}
                        onChange={this.handleCheck('checkedE')}
                        value="checkedE"
                        color="primary"
                      />
                    }
                    label="ACT Prep"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedF}
                        onChange={this.handleCheck('checkedF')}
                        value="checkedF"
                        color="primary"
                      />
                    }
                    label="SAT2 Prep"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedG}
                        onChange={this.handleCheck('checkedG')}
                        value="checkedG"
                        color="primary"
                      />
                    }
                    label="AP Enrichment"
                  />
                  <FormControlLabel
                  className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedH}
                        onChange={this.handleCheck('checkedH')}
                        value="checkedH"
                        color="primary"
                      />
                    }
                    label="SSAT Prep"
                  />
                  <FormControlLabel
                    className={styles.formControlLabel}
                    control={
                      <Checkbox
                        checked={this.state.checkedI}
                        onChange={this.handleCheck('checkedI')}
                        value="checkedI"
                        color="primary"
                      />
                    }
                    label="English Language Arts"
                  />
                </div>
                <TextField
                  id="other"
                  label="Anything else you'd like us to know?"
                  className={styles.textField}
                  value={this.state.wechat}
                  onChange={this.handleChange('grade')}
                  margin="normal"
                  multiline
                  rows="3"
                  variant="outlined"
                />
              </form>
              {this.state.showAlert ?
                <div className={[styles.description, styles.primary].join(' ')}>
                  Thank you for contacting us. We'll be in touch shortly
                </div>
                : 
                null
              }
              <div className={styles.button} onClick={(e) => this.handleFormSubmit(e)}>Contact Us</div>
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.description}>
              We have two offices in Shanghai, conveniently located nearby international schools in Gubei and Pudong.
            </div>
            <div className={styles.mapWrapper}>
              <div className={styles.mapElement}>
                <img src={map_pudong} />
                <div className={styles.description}>2/F, Block A, 1146 Biyun Road<br />Pudong New District, Shanghai</div>
                <div className={styles.description}>上海市浦东新区碧云路1146号A座2楼</div>
              </div>
              <div className={styles.mapElement}>
                
                <img src={map_puxi} />
                <div className={styles.description}>205, No. 118, East Ronghua Avenue<br />Changning District, Shanghai</div>
                <div className={styles.description}>上海市长宁区荣华东道118号205</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}