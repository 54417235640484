import React from 'react';
import { Link } from 'gatsby'

import styles from './footer.module.scss';

import {footer_3} from '../images'

export default () => (
  <div className={styles.footer}>
    <Link to='/contact/main'><div className={styles.button}>Questions? Get in Touch!</div></Link>
    <div className={styles.sitemap}>
      <div className={styles.column}>
        <Link to='/' className={styles.bold}>Home</Link>
        <Link to='/team/main'>Our Team</Link>
        <Link to='team/contact'>Contact Us</Link>
      </div>
      <div className={styles.column}>
        <Link to='/test-prep/main' className={styles.bold}>Test Preparation</Link>
        <Link to='/products/sat-prep'>SAT Prep</Link>
        <Link to='/products/act-prep'>ACT Prep</Link>
        <Link to='/products/ssat-prep'>SSAT Prep</Link>
        <Link to='/products/english-language-arts'>English Language Arts</Link>
        <Link to='/products/ap-enrichment'>AP Enrichment</Link>
        <Link to='/products/sat-2-prep'>SAT II Prep</Link>
      </div>
      <div className={styles.column}>
        <Link to='/consulting/main' className={styles.bold}>High School & College Counseling</Link>
        <Link to='/products/high-school-counseling'>High School Counseling</Link>
        <Link to='/products/college-counseling'>College Counseling</Link>
        <Link to='/products/academic-mentorship'>Academic Mentorship</Link>
      </div>
    </div>
  </div>
)