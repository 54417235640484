import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components';

import { Logo, logo_jpg } from '../images'
import styles from './header.module.scss';
//import mobileStyles from './headerMobile.module.scss';


import { slide as Menu } from 'react-burger-menu'

class Header extends React.Component {

  constructor(props) {
    super(props) 

    this.state = {
      isOpen: false,
      isScrolled: false,
    }

    this._scroller = this._scroller.bind(this);
  }

  closeMenu() {
    this.setState({
      isOpen: false
    })
  }
  /*
  listenScrollEvent = e => {
    
    if (window.scrollY > 200) {
      this.setState({isScrolled: true})
    }
  }
  */

  _scroller(e) {
    if (window.scrollY > 220) {
      this.setState({isScrolled: true})
    }

    if (window.scrollY < 40) {
      this.setState({isScrolled: false})
    }
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this._scroller);
    }

    // just putting this here so that I can reimplement later
    // <div className={this.state.isScrolled ? styles.navWhite : styles.nav} >
  }


  render() {
    const { t } = this.props;
    return(
      <div className={styles.navWhite} >
        <Link to="/"><img src={Logo} className={styles.logo}/></Link>
        <div className={styles.navRight}> 
          <Link to='/consulting/main'>Counseling</Link>
            <Link to='/test-prep/main'>Test Prep</Link>
            {/*<Link to='/students/main'>Our Students</Link>*/}
            <Link to='/team/main'>Our Team</Link>
            <Link to='/contact/main'>Contact Us</Link>
        </div>
        <div className={styles.mobileNav}>
        <StyledBurgerMenu>
          <Menu right width='90%' isOpen={this.state.isOpen}>
            <Link to='/consulting/main'>Consulting</Link>
            <Link to='/test-prep/main'>Test Prep</Link>
            {/*<Link to='/students/main'>Our Students</Link>*/}
            <Link to='/team/main'>Our Team</Link>
            <Link to='/contact/main'>Contact Us</Link>
          </Menu>
        </StyledBurgerMenu>
      </div>
    </div>
   )
  }
}

export default Header

const StyledBurgerMenu = styled.div`
    .bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 36px !important;
  right: 36px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  height: 36px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  background: rgba(0, 0, 0, 0.5);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  text-align:center;  
  display: inline-block;
  text-decoration: none;
  margin-bottom: 5vh;
  color: white;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
`
